@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #F5F6FA;
}
.rtl-grid {
  direction: rtl;
}

@font-face {
  font-family: 'bold';
  src: url('../src/assets/fonts/DINNextLTArabic-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'regular';
  src: url('../src/assets/fonts/DINNextLTArabic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}